import * as React from "react"
import Layout from '../components/Layout/Layout'

const IndexPage = () => {
  return (
   <Layout>
    <h1>Site is under construction</h1>
   </Layout>
  )
}

export default IndexPage
